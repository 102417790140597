<template>
  <div ref="container" class="collection">
    <div class="header acea-row row-center-wrapper" ref="header">
      <div class="black" @click="returnChange()">
        <img src="@assets/images/icon_black.png" alt="" />
      </div>
      <p>我的收藏</p>
      <p class="admin" v-if="adminShow" @click="adminChange">编辑</p>
      <p class="admin" v-if="!adminShow" @click="adminChange">完成</p>
    </div>
    <div class="collect-menu acea-row row-left">
      <div class="list">
        <p>时间排序：</p>
      </div>
      <div class="list" :class="item.id===menuId? 'on':''" v-for="(item,index) in menuData" :key="index" @click="getMenuChange(item.id)">
        <p>{{item.title}}</p>
      </div>
    </div>
    <div class="collect-list" v-if="collectProductList.length > 0">
      <div class="item" v-for="(item,index) in collectProductList" :key="index">
        <div class="acea-row row-left" @click.stop="goDetail(item.pid)">
          <div class="left">
            <img :src="item.image" alt="" />
          </div>
          <div class="right">
            <div class="dest">
              <p class="tit">{{item.store_name}}</p>
              <p class="time">收藏时间：{{item.add_time}}</p>
            </div>
            <div class="sku">
              <p>规格：{{item.spec}}</p>
              <p class="acea-row row-between">
                编号：{{item.product_number}} 
                <!--<a href="">查看商品</a>-->
              </p>
            </div>
          </div> 
        </div>
        <div class="checkbox-wrapper" v-if="!adminShow">
          <label class="well-check">
            <input
              type="checkbox"
              name=""
              @change.stop="singleChecked"
              :value="item.pid"
              v-model="checkedCode"
            />
            <i class="icon"></i>
          </label>
        </div>
      </div>
    </div>
    <div class="detail-btn acea-row row-right" v-if="!adminShow">
      <div class="right" @click="delCollection">
        <p>取消收藏</p>
      </div>
    </div>
    <Loading :loaded="loadend" :loading="loading"></Loading>
    <div
      class="noCommodity"
      style="background-color:#fff; height:100%"
      v-if="collectProductList.length < 1 && page > 1"
    >
      <div class="noPictrue">
        <img src="@assets/images/noCollection.png" class="image" />
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { getCollectUser,getCollectDel } from "@api/user";
import Loading from "@components/Loading";
export default {
  name: "GoodsCollection",
  components: {
    Loading
  },
  data(){
    return {
      adminShow: true,
      checkedCode: [], // 用于保存被选中的数据
      page: 1,
      limit: 10,
      collectProductList: [],
      loadTitle: "",
      loading: false,
      loadend: false,
      menuData:[{id:'',title:'最新'},{id:3,title:'三个月内'},{id:6,title:'半年前'}],
      menuId:'',
    }
  },
  mounted(){
    this.get_user_collect_product();
      this.$scroll(this.$refs.container, () => {
        !this.loading && this.get_user_collect_product();
    });
  },
  methods:{
    returnChange() {
      var that = this;
      that.$router.go(-1);
    },
    //选择列表
    singleChecked(){
      // var that = this;
      // for (var i in that.collectProductList) {
      //   if (that.checkedCode.indexOf(that.collectProductList[i].pid) > -1) {
          
      //   }
      // }
    },

    //点击列表
    goDetail(id){
      var that = this;
      that.$router.push({ path: "/detail/" + id });
    },
    //点击菜单
    getMenuChange(e){
      var that = this;
      that.menuId = e;
      that.loading = false;
      that.loadend = false;
      that.page = 1;
      that.collectProductList = [];
      that.get_user_collect_product();
    },
    //点击管理
    adminChange: function() {
      let that = this;
      if (that.adminShow) {
        that.adminShow = false;
      } else {
        that.adminShow = true;
      }
    },
    get_user_collect_product: function() {
      let that = this;
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      getCollectUser(that.page, that.limit, that.menuId).then(res => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.collectProductList.push.apply(that.collectProductList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
      });
    },
    //删除收藏；
    delCollection: function() {
      let that = this;
      console.log(that.checkedCode)
      that.loadend = false;
      // id = that.collectProductList[index].pid,
      // category = that.collectProductList[index].category;
      getCollectDel(that.checkedCode.toString(), '').then(function() {
        that.$dialog.toast({
          mes: "删除收藏成功!",
          callback: () => {
            that.collectProductList = [];
            that.page = 1;
            that.get_user_collect_product();
            // that.collectProductList.splice(index, 1);
            // that.$set(that, "collectProductList", that.collectProductList);
          }
        });
      });
    }
  }
}
</script>
<style type="text/css">

.collection {
  padding-top:50px;
  height: calc(100% - 90px)
}
  
.collection .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.collection .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.collection .header .black img {
  width: 100%;
  height: 100%;
}
.collection .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}

.collection .header p.admin {
  position: absolute;
  right: 8px;
  top: 15px;
  font-size: 14px;
  color: #666;
  width:auto;
}

.noCart {
  margin-top: 0.17rem;
  padding-top: 0.1rem;
}

.noCart .pictrue {
  width: 4rem;
  height: 3rem;
  margin: 0.7rem auto 0.5rem auto;
}

.noCart .pictrue img {
  width: 100%;
  height: 100%;
}


</style>





<!-- <template>
  <div ref="container">
    <div class="collectionGoods" v-if="collectProductList.length > 0">
      <router-link
        :to="{ path: '/detail/' + item.pid }"
        class="item acea-row row-between-wrapper"
        v-for="(item, index) in collectProductList"
        :key="index"
      >
        <div class="pictrue"><img :src="item.image" /></div>
        <div class="text acea-row row-column-between">
          <div class="infor line1">{{ item.store_name }}</div>
          <div class="acea-row row-between-wrapper">
            <div class="money font-color-red">￥{{ item.price }}</div>
            <div class="delete" @click.prevent="delCollection(index)">删除</div>
          </div>
        </div>
      </router-link>
    </div>
    <Loading :loaded="loadend" :loading="loading"></Loading>
    <div
      class="noCommodity"
      style="background-color:#fff;"
      v-if="collectProductList.length < 1 && page > 1"
    >
      <div class="noPictrue">
        <img src="@assets/images/noCollection.png" class="image" />
      </div>
      <Recommend></Recommend>
    </div>
  </div>
</template>
<script>
import Recommend from "@components/Recommend";
import { getCollectUser, getCollectDel } from "@api/user";
import Loading from "@components/Loading";
export default {
  name: "GoodsCollection",
  components: {
    Recommend,
    Loading
  },
  props: {},
  data: function() {
    return {
      page: 1,
      limit: 20,
      collectProductList: [],
      loadTitle: "",
      loading: false,
      loadend: false
    };
  },
  mounted: function() {
    this.get_user_collect_product();
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.get_user_collect_product();
    });
  },
  methods: {
    get_user_collect_product: function() {
      let that = this;
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      getCollectUser(that.page, that.limit).then(res => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.collectProductList.push.apply(that.collectProductList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
      });
    },
    //删除收藏；
    delCollection: function(index) {
      let that = this,
        id = that.collectProductList[index].pid,
        category = that.collectProductList[index].category;
      getCollectDel(id, category).then(function() {
        that.$dialog.toast({
          mes: "删除收藏成功!",
          callback: () => {
            that.collectProductList.splice(index, 1);
            that.$set(that, "collectProductList", that.collectProductList);
          }
        });
      });
    }
  }
};
</script> -->
